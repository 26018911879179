label[for="html5-qrcode-private-filescan-input"] {
    display: flex;
    justify-content: start;
    align-items: start;
    width: 100%;
    overflow: hidden;
}

#qr-canvas-visible {
    width: 200px !important;
    height: 100px !important;
    object-fit: contain;
}
